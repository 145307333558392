import { useCallback, useMemo, useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { FormFeedback, Input } from "reactstrap";

import styled from "@emotion/styled";

import { ReactComponent as ChevronIcon } from "~assets/images/icons/chevron.svg";
import Utils from "~helpers/Utils";

import "./style.scss";

const StyledDiv = styled.div`
  &:hover,
  &:focus,
  &:active {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  }
  max-width: 50px;
  .pr-sel__indicator {
    padding: 11.5px 6px !important;
    svg {
      width: 15px;
    }
  }
`;

const defaultColor = "#28aae1";
/** @type {typeof AsyncSelect} */
const PRSelect = forwardRef(function PRSelect(
  {
    invalid,
    labelSelector = "label",
    valueSelector = "value",
    labelRenderer,
    lazy,
    dropdownIcon,
    onChange,
    onBlur,
    name,
    id,
    noWrap,
    menuPortal,
    value: valueProp,
    isPrimitiveValue,
    options: optionsProp,
    isOptionReadonly,
    fullWidth,
    isMulti,
    isLoading,
    placeholder: placeholderProp,
    isDisabled,
    loadOptions,
    noBorder,
    size = "md",
    cacheOptions,
    defaultOptions,
    classNamePortal,
    lazySearchDebounce = 250,
    ...rest
  },
  ref
) {
  const [remoteOptions, setRemoteOptions] = useState([]);
  const abortControllerRef = useRef();
  const [key, setKey] = useState(Utils.getId());
  const previousValueRef = useRef(null);
  const { t } = useTranslation();

  const placeholder = useMemo(() => {
    return placeholderProp || t("select.placeholder");
  }, [placeholderProp, t]);

  const getOptionLabel = useCallback(
    (option) => {
      if (labelRenderer) {
        return labelRenderer(option);
      }
      return get(option, labelSelector);
    },
    [labelSelector, labelRenderer]
  );
  const getOptionValue = useCallback((option) => get(option, valueSelector), [valueSelector]);
  const handleChange = useCallback(
    (item, actionType) => {
      // const primitiveValue = item?.[valueSelector];
      const primitiveValue = isMulti ? item?.map((i) => get(i, valueSelector)) : get(item, valueSelector);
      const value = isPrimitiveValue ? primitiveValue : item;
      return onChange?.(value, actionType, item);
    },
    [valueSelector, onChange, isPrimitiveValue, isMulti]
  );
  const { options, flattenOptions } = useMemo(() => {
    const optionsRaw = optionsProp || remoteOptions;
    const options = optionsRaw?.map((option) => {
      const LabelData = get(option, labelSelector);
      const label =
        typeof LabelData === "function" ? (
          <LabelData option={option} />
        ) : typeof LabelData === "string" ? (
          t(LabelData)
        ) : (
          LabelData
        );
      return {
        ...option,
        label: label,
      };
    });

    const flattenOptions = options?.flatMap((option) => {
      if (option?.options) {
        return option?.options;
      }
      return option;
    });
    return {
      options: options,
      flattenOptions: flattenOptions,
    };
  }, [optionsProp, remoteOptions, t]);

  const disabled = isLoading || isDisabled;
  const getDisabled = useCallback((option) => option?.disabled, []);
  const styles = useMemo(
    () => ({
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        boxShadow: "none",
        borderColor: invalid ? "#f46a6a" : "#ced4da",
        "&:hover": {
          borderColor: invalid ? "#f46a6a" : "#ced4da",
          ...(invalid && { boxShadow: "0 0 0 0.2rem rgba(220,53,69,.25)" }),
        },
        // height: "calc(1.5em + .94rem + 2px)", // Bootstrap default input height
        minHeight: "calc(1.5em + .94rem + 2px)",
        ...(size === "sm" && {
          minHeight: "calc(1.5em + 0.5rem + 2px)",
          fontSize: "0.7109375rem",
          borderRadius: "0.2rem",
          padding: 0,
          "& > div": {
            minHeight: "calc(1.5em + 0.5rem + 1px)",
            padding: "0 0.5rem",

            // display: "contents",
          },
        }),
        ...(noWrap && { flexWrap: "nowrap" }),
        // maxWidth: "200px",
        minWidth: "100px",
        ...(noBorder && { border: "none !important" }),
      }),
      menu: (styles) => ({
        ...styles,
        zIndex: 9999,
      }),

      menuPortal: (styles) => ({
        ...styles,
        zIndex: 99999,
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color || defaultColor);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? data?.color || defaultColor
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data?.color || "initial",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled
              ? isSelected
                ? data?.color || defaultColor
                : color.alpha(0.3).css()
              : undefined,
          },
        };
      },
      indicatorsContainer: (styles) => {
        return {
          ...styles,
          "& > div": {
            padding: "6px",
          },
          ...(size === "sm" && {
            "& > div": {
              padding: "0px 0px 0px 5px",
            },
          }),
        };
      },
      multiValue: (styles, context) => {
        const isReadonly = context?.data?.readonly !== undefined || !!isOptionReadonly?.(context?.data);

        return {
          ...styles,
          ...(isReadonly && {
            backgroundColor: "#ccc",
          }),
        };
      },
      multiValueRemove: (styles, context) => {
        const isReadonly = context?.data?.readonly !== undefined || !!isOptionReadonly?.(context?.data);

        return {
          ...styles,
          ...(isReadonly && {
            visibility: "hidden",
            width: 0,
            padding: "0px 2px",
          }),
        };
      },
    }),
    [invalid, noWrap, isOptionReadonly, noBorder]
  );

  const Component = lazy ? AsyncSelect : Select;

  const value = useMemo(() => {
    if (!isPrimitiveValue) {
      return {
        ...valueProp,
        label: (typeof valueProp?.label === "string" ? t(valueProp?.label) : valueProp?.label) || "",
      };
    }

    if (isMulti) {
      return flattenOptions?.filter((option) => valueProp?.includes(get(option, valueSelector))) || [];
    }
    const opt = lazy
      ? remoteOptions?.length
        ? remoteOptions
        : Array.isArray(defaultOptions)
        ? defaultOptions
        : []
      : flattenOptions;

    const data = opt?.find((option) => get(option, valueSelector) === valueProp) || [];
    return data;
  }, [valueProp, valueSelector, isPrimitiveValue, flattenOptions, isMulti, lazy, remoteOptions, t]);

  useEffect(() => {
    // Re-render when initial value is set for Lazy Select to trigger loadOptions function again
    if (!previousValueRef.current && valueProp && lazy) {
      previousValueRef.current = valueProp;
      setKey(Utils.getId());
    }
  }, [valueProp, lazy]);

  const handleOnMenuClose = () => {
    onBlur?.({ target: { name, id, value }, type: "blur" }); // TODO: Fix this with real event
  };
  const getLoadingText = useCallback(({ inputValue = "" }) => {
    return `${i18next.t("select.loading")} ${inputValue}...`;
  }, []);

  const handleLoadOptions = useCallback(
    async (inputValue, callback) => {
      if (loadOptions) {
        try {
          abortControllerRef.current?.abort();
          const controller = new AbortController();
          abortControllerRef.current = controller;
          await Utils.wait(lazySearchDebounce);
          if (controller.signal.aborted) return;
          let options = [];
          try {
            options = await loadOptions(inputValue, callback, abortControllerRef.current.signal, valueProp);
          } catch (error) {
            console.error(error);
          }
          setRemoteOptions(options);
          return options;
        } catch (error) {
          abortControllerRef.current?.abort();
          abortControllerRef.current = null;
        }
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadOptions, lazySearchDebounce]
  );

  useImperativeHandle(ref, () => ({
    refresh: () => {
      setKey(Utils.getId());
    },
  }));

  // const customComponents = useMemo(() => {
  //   return {
  //     MenuList: ({ children, ...rest }, a, b) => {
  //       return (
  //         <PRScrollbar
  //           style={{
  //             maxHeight: rest.maxHeight,
  //           }}
  //         >
  //           {children}
  //         </PRScrollbar>
  //       );
  //     },
  //   };
  // }, []);
  const DropdownIndicator = useCallback(
    (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <StyledDiv>
            {dropdownIcon ? typeof dropdownIcon === "function" ? dropdownIcon() : dropdownIcon : <ChevronIcon />}
          </StyledDiv>
        </components.DropdownIndicator>
      );
    },
    [dropdownIcon]
  );

  const lazyProps = lazy
    ? {
        cacheOptions: cacheOptions ?? key,
        defaultOptions: defaultOptions ?? true,
        ...(loadOptions && { loadOptions: handleLoadOptions }),
      }
    : {};
  return (
    <div
      className={classNames("pr-select", {
        "w-100": fullWidth,
      })}
      disabled={disabled}
    >
      <Component
        key={key}
        backspaceRemovesValue
        isClearable
        menuShouldBlockScroll
        components={{ DropdownIndicator }}
        getOptionLabel={getOptionLabel}
        id={id}
        isDisabled={disabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isOptionDisabled={getDisabled}
        loadingMessage={getLoadingText}
        name={name}
        options={options}
        styles={styles}
        value={value}
        onMenuClose={handleOnMenuClose}
        classNamePrefix="pr-sel"
        // components={customComponents}
        placeholder={isLoading ? i18next.t("select.loading") + "..." : placeholder}
        getOptionValue={getOptionValue}
        // menuPosition="fixed"
        onChange={handleChange}
        {...(menuPortal
          ? {
              menuPortalTarget:
                typeof menuPortal === "object"
                  ? menuPortal
                  : document.querySelector(typeof menuPortal === "string" ? menuPortal : "#root"),
            }
          : {})}
        {...lazyProps}
        {...rest}
        classNames={{
          menuPortal: () => classNames("pr-select", classNamePortal),
        }}
      />
      {typeof invalid === "string" && invalid?.length > 0 && (
        <>
          <Input className="d-none" invalid={!!invalid} />
          {invalid && <FormFeedback type="invalid">{invalid}</FormFeedback>}
        </>
      )}
    </div>
  );
});
export default PRSelect;
