import React, { useEffect, useMemo, useState } from "react";

import { PalButton as LibPalButton } from "@palamar/fe-library";
import classNames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "@emotion/styled";
import { Box, Collapse, Grid } from "@mui/material";

import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import useQueryParams from "~common/hooks/useQueryParams";
import PRChatMessage from "~components/Generic/PRChatMessage";
import Footer from "~components/Layout/Footer";
import PalLink from "~components/mui/PalLink";
import PRChatMessageFilePopper from "~components/PRChatMessageFilePopper";
import { ticketStatus, ticketStatusOptions } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import { appendPublicTicketComment, approvePublicTicket, getPublicTicket } from "~store/helpdesk/actions";

const Title = styled.div`
  color: ${(props) => props.theme.palette.primary.dark};
  font-weight: 600;
  font-size: 36px;
  font-family: Museo Sans;
  line-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const ApproveButton = styled(LibPalButton)`
  font-size: 18px;
  border-radius: 26px;
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.light};
  border-color: ${(props) => props.theme.palette.primary.light};
  max-width: 150px;
  height: 31px;
  margin-top: -31px; //height of the button
  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 100%;
    margin-top: 10px;
  }
`;

const StyledDiv = styled.div`
  /* font-family: Museo Sans; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100% - 80px);
  padding: 30px 50px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }
  .ticket-box {
    margin: 5px auto auto auto;
    ${(props) => props.theme.breakpoints.down("md")} {
      max-width: 100%;
      margin: 30px auto auto;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ticket-detail {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    background-color: #f7f7f7;
    padding: 35px 35px 30px;
    max-width: ${(props) => props.theme.breakpoints.values.lg}px;

    ${(props) => props.theme.breakpoints.down("md")} {
      padding: 35px 15px 30px;
    }

    .ticket-card {
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 30px;
      padding: 20px;
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      color: #000;
      white-space: pre-wrap;
      word-wrap: break-word;
      &.chat-box {
        overflow: hidden;
        max-height: min(420px, 100vh - 420px);
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        .chat-area {
        }
      }
    }
    .ticket-card-title {
      padding: 0 20px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 22px;
      color: #000;
    }
  }
`;

const StyledPRChatMessage = styled(PRChatMessage)`
  min-height: 420px;
`;
export default function CustomerTicketDetail() {
  const { share_token } = useQueryParams();
  const [ticketData, setTicketData] = useState({});
  const { alias } = useParams();
  const [token, setToken] = useState(share_token);
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  const [chatInputMessage, setChatInputMessage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const browserLang = navigator?.language || navigator?.userLanguage || "en";
    i18next.changeLanguage(browserLang);
  }, []);

  useEffect(() => {
    document.title = t("public.ticketDetail.pageTitle");
  }, [t]);

  useEffect(() => {
    if (!token) return;
    dispatch(getPublicTicket(alias, token))
      .then((result) => {
        setTicketData(result);
        // if (!share_token) {
        //   HistoryHelper.push({ pathname: "/ticketdetail", search: `?share_token=${token}` });
        // }
      })
      .catch(() => {
        setTicketData(null);
      });
  }, [token, dispatch, alias]);

  useEffect(() => {
    setToken(share_token);
    if (!share_token) {
      setTicketData({});
    }
  }, [share_token]);

  useEffect(() => {
    if (!share_token) {
      AlertHelper.showError(t("public.ticketDetail.ticketNotFound"));
    }
  }, [share_token, t]);
  const chatMessages = useMemo(() => {
    let previousCommentTime = null;
    const messages = [];
    const comments = [...(ticketData?.comments || []).map((item) => ({ ...item }))];
    comments.forEach((comment) => {
      comment.created = DateHelper.getDateTimeLocal(comment?.created);
    });

    comments.sort((a, b) => {
      return a.created.isBefore(b.created) ? -1 : 1;
    });

    for (const comment of comments) {
      const commentTime = comment?.created;
      const isSameDay = commentTime.isSame(previousCommentTime, "day");
      if (!previousCommentTime || !isSameDay) {
        messages.push({
          type: "notification",
          position: "center",
          sender_type: "SYS",
          messageTime: commentTime,
          text: commentTime.format("LLL"),
          isSent: true,
        });
      }

      if (comment?.new_files?.length) {
        for (const file of comment?.new_files) {
          const IsImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file?.file_name);
          messages.push({
            type: IsImage ? "image" : "file",
            position: comment?.creator === "CUS" ? "right" : "left",
            sender_type: comment?.user?.is_bot ? "BOT" : "AGT",
            messageTime: comment?.created,
            text: file?.name,
            isSent: true,
            fileName: file?.file_name,
            ...(IsImage ? { imageUrl: file?.file_url } : { fileUrl: file?.file_url }),
          });
        }
      }

      if (comment?.comment) {
        messages.push({
          type: "text",
          position: comment?.creator === "CUS" ? "right" : "left",
          sender_type: comment?.user?.is_bot ? "BOT" : "AGT",
          messageTime: comment?.created,
          text: comment?.comment,
          isSent: true,
        });
      }
      previousCommentTime = commentTime;
    }
    if (!messages?.length) {
      messages.push({
        type: "notification",
        position: "center",
        sender_type: "SYS",
        messageTime: DateHelper.getDateTimeLocal(),
        text: i18next.t("public.ticketDetail.endOfMessages"),
        isSent: true,
      });
    }
    return messages;
  }, [ticketData]);

  const handleMessage = async (message, e) => {
    if (
      !(await DialogHelper.showQuestionYesNo(
        t("public.ticketDetail.sendTicketMessageTitle"),
        t("public.ticketDetail.sendTicketMessage")
      ))
    ) {
      e.preventDefault();
      return;
    }

    const form = new FormData();

    if (message.text) {
      form.append("comment", message.text);
    }
    selectedFiles.forEach((file) => {
      form.append("files", file);
    });
    await dispatch(appendPublicTicketComment(alias, token, form));
    setSelectedFiles([]);
    setChatInputMessage("");
    const result = await dispatch(getPublicTicket(alias, token));
    setTicketData(result);
  };

  const handleClickApprove = async () => {
    if (
      !(await DialogHelper.showQuestionYesNo(
        t("public.ticketDetail.approveTicketConfirmTitle"),
        t("public.ticketDetail.approveTicketConfirmDesc")
      ))
    ) {
      return;
    }
    await dispatch(approvePublicTicket(alias, token));
    const result = await dispatch(getPublicTicket(alias, token));
    setTicketData(result);
  };

  const LabelComp = useMemo(
    () => ticketStatusOptions.find((item) => item.value === ticketData?.status)?.label || (() => ticketData?.status),
    [ticketData?.status]
  );

  const handleDrop = async (files) => {
    if (!files?.length) return;
    setSelectedFiles(files);
  };

  if (!share_token) return null;
  return (
    <StyledDiv>
      <div>
        <PalLink noPrefix to="/">
          <Logo />
        </PalLink>
      </div>
      <div className="ticket-box">
        <Title>{t("public.ticketDetail.ticketSupport")}</Title>
        <Box alignItems={"center"} display="flex" justifyContent={"flex-end"} width="100%">
          <ApproveButton
            fullWidth
            disabled={ticketData?.status === ticketStatus.APPROVED || !ticketData}
            loading={false}
            variant="contained"
            onClick={handleClickApprove}
          >
            {t("public.ticketDetail.approve")}
          </ApproveButton>
        </Box>
        <div className="ticket-detail">
          <div className="ticket-card-title">{t("public.ticketDetail.ticketInfo")}</div>
          <div className="ticket-card">
            <Grid container columnSpacing={"20px"} rowSpacing={"12px"}>
              <Grid item md={3} sm={4} xs={5}>
                {t("public.ticketDetail.sharedBy")}:
              </Grid>
              <Grid item md={9} sm={8} xs={7}>
                {ticketData?.shared_by?.full_name} {ticketData?.shared_by?.email && `(${ticketData?.shared_by?.email})`}
              </Grid>
              <Grid item md={3} sm={4} xs={5}>
                {t("public.ticketDetail.ticketStatus")}
              </Grid>
              <Grid item md={9} sm={8} xs={7}>
                <LabelComp />
              </Grid>
              <Grid item md={3} sm={4} xs={5}>
                {t("public.ticketDetail.ticketEmail")}
              </Grid>
              <Grid item md={9} sm={8} xs={7}>
                <div>{ticketData?.contact}</div>
              </Grid>
            </Grid>
            <Collapse in={showDetail}>
              <Grid container columnSpacing={"20px"} mt={0.5} rowSpacing={"12px"}>
                <Grid item md={3} sm={4} xs={5}>
                  {t("common.id")}:
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  <div>{ticketData?.share_token}</div>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                  {t("public.ticketDetail.ticketCategory")}
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  <div>{ticketData?.category_name}</div>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                  {t("public.ticketDetail.ticketDate")}
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  <div>
                    {DateHelper.getDateTimeLocal(ticketData?.created).format("LLL")} (
                    {DateHelper.getDateTimeLocal(ticketData?.created).fromNow()})
                  </div>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                  {t("public.ticketDetail.ticketLastUpdate")}
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  <div>{DateHelper.getDateTimeLocal(ticketData?.updated).format("LLL")}</div>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                  {t("public.ticketDetail.agentStatus")}
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  <div
                    className={classNames({
                      "fw-medium": ticketData?.assigned_user_name,
                    })}
                  >
                    {ticketData?.assigned_user_name
                      ? t("public.ticketDetail.assigned")
                      : //
                        t("public.ticketDetail.unassigned")}
                  </div>
                </Grid>
                <Grid item md={3} sm={4} xs={5}>
                  {t("public.ticketDetail.assignDate")}
                </Grid>
                <Grid item md={9} sm={8} xs={7}>
                  {ticketData?.assigned_user_name ? (
                    <div>{DateHelper.getDateTimeLocal(ticketData?.assigned_time).format("LLL")}</div>
                  ) : (
                    "-"
                  )}
                </Grid>
              </Grid>
            </Collapse>
            <Grid container columnSpacing={"20px"} mt={1} rowSpacing={"12px"}>
              <Grid item md={9} sm={8} xs={7}>
                <LibPalButton variant="text" onClick={() => setShowDetail(!showDetail)}>
                  {showDetail ? t("chatbot.ticket.hideDetails") : t("chatbot.ticket.showDetails")}
                </LibPalButton>
              </Grid>
            </Grid>
          </div>
          {ticketData?.comment && (
            <>
              <div className="ticket-card-title">{t("chatbot.ticket.shareDescription")}</div>
              <div className="ticket-card">{ticketData?.comment}</div>
            </>
          )}
          <div className="ticket-card-title">{t("public.ticketDetail.description")}</div>
          <div className="ticket-card">{ticketData?.text}</div>

          <div className="ticket-card-title">{t("public.ticketDetail.messages")}</div>
          <PRChatMessageFilePopper files={selectedFiles} onChange={setSelectedFiles} />
          <StyledPRChatMessage
            // senderType={session.session_type === chatbotSessionStatus.AGENT_CHAT ? "AGT" : "BOT"}
            disableAvatar
            dropDisabled
            enableBlankMessage
            multiline
            noReturnToHome
            showAttachment
            showFilePreview
            className="ticket-card chat-box"
            colorPrimary={"#28aae1"}
            disableButton={!selectedFiles?.length && !chatInputMessage}
            disableMic={ticketData?.status === ticketStatus.APPROVED}
            disableTextInput={ticketData?.status === ticketStatus.APPROVED}
            dropMaxFiles={5}
            focusOnMount={false}
            focusOnRender={false}
            messages={chatMessages}
            reverseChat={true}
            onDrop={handleDrop}
            onMessage={handleMessage}
            onMessageChange={setChatInputMessage}
          />
        </div>
      </div>
      <Footer />
    </StyledDiv>
  );
}
